import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../../components/Footer";
import Header from "../../../../components/Header";
import banner from "../../../../staticAssets/Horoscope Bg.png";
import Leo from "../../../../staticAssets/Leo.png";
import Aries from "../../../../staticAssets/Aries.png";
import Taurus from "../../../../staticAssets/Taurus.png";
import Gemini from "../../../../staticAssets/Gemini.png";
import Cancer from "../../../../staticAssets/Cancer.png";
import Virgo from "../../../../staticAssets/Virgo.png";
import Libra from "../../../../staticAssets/Libra.png";
import Scorpio from "../../../../staticAssets/Scorpio.png";
import Capricorn from "../../../../staticAssets/Capricorn.png";
import Aquarius from "../../../../staticAssets/Aquarius.png";
import Pisces from "../../../../staticAssets/Pisces.png";
import Sagittarius from "../../../../staticAssets/Sagittarius.png";

import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../../context/Auth";
import { AnalyticsContext } from "../../../../context/Analytics";

import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet-async";

function Index({location}) {
  const { site } = useStaticQuery(
    graphql`
      query  {
        site {
          siteMetadata {
            seos {
              title
              description
              canonicalUrl
              path
              _xv
            }
          }
        }
      }
    `
  );
  const [horoscope, sethoroscope] = useState("");
const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
const { user } = useContext(AuthContext) || {
  user: {},
};
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    fn();
  }, []);
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://api.bodhiness.com/v1/horoscope/General/Yearly?language=hindi"
      );

      if (res.data.statusCode == 200) {
        // setblogs([...res.data]);
        sethoroscope(res?.data?.payLoad?.info);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Helmet>
        <meta
          name="title"
          content={`${
            site.siteMetadata.seos.filter((value) => {
              return (
                value.path ==
                location.pathname.slice(0,-1)
              );
            })[0]?.title
          }`}
        />
        <meta
          name="description"
          content={`${
            site.siteMetadata.seos.filter((value) => {
              return (
                value.path ==
                location.pathname.slice(0,-1)
              );
            })[0]?.description
          }`}
        />
        <link
          rel="canonical"
          href={`${
            site.siteMetadata.seos.filter((value) => {
              return (
                value.path ==
                location.pathname.slice(0,-1)
              );
            })[0]?.canonicalUrl
          }`}
        />
      </Helmet>

      <Header />
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
        
          <div className="w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 w-screen md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                 सालाना राशिफल
              </h1>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="container mx-auto flex flex-wrap my-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer ml-4"
              >
                <h4 className="text-orange-600 text-sm mx-1">Bodhi</h4>
              </a>
              <h4 className="mx-1 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/hindi/rashifal/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm mx-1">Rashifal</h4>
              </a>
              <h4 className="mx-1 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/hindi/rashifal/yearly-rashifal/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm mx-1">Yearly Rashifal</h4>
              </a>
             
            </div>
            {/* <div className="container mx-auto flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={Aries} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">
                  <h1 className="font-semibold text-xl md:text-4xl ml-4">
                    Aries
                  </h1>
                  <h2 className="text-sm md:text-base ml-4">{(new Date()).getFullYear()}</h2>
                </div>
                <div className=""></div>
              </div>
            </div> */}
            <div className="container mx-auto">
              <div className="flex gap-6 overflow-x-scroll py-4 hide-scroll-bar scrollbar-hide">
                <a className="flex flex-nowrap" href="https://www.bodhi.app/hindi/rashifal/yearly-rashifal/">
                  <button className="bg-white px-4 md:px-6 py-0.5 md:py-1 text-gray-400 border border-gray-400 rounded-lg text-sm md:text-lg ml-4">
                  दैनिक
                  </button>
                </a>
                <a className="flex flex-nowrap" href="https://www.bodhi.app/hindi/rashifal/weekly-rashifal/">
                  <button className="bg-white px-4 md:px-6 py-0.5 md:py-1 text-gray-400 border border-gray-400 rounded-lg text-sm md:text-lg">
                  साप्ताहिक
                  </button>
                </a>
                <a className="flex flex-nowrap" href="https://www.bodhi.app/hindi/rashifal/monthly-rashifal/">
                  <button className="bg-white px-4 md:px-6 py-0.5 md:py-1 text-gray-400 border border-gray-400 rounded-lg text-sm md:text-lg">
                  मासिक
                  </button>
                </a>
                <a className="flex flex-nowrap" href="https://www.bodhi.app/hindi/rashifal/yearly-rashifal/">
                  <button className="bg-gradient-to-r from-[#FEB458] to-[#ED9108] px-4 md:px-6 py-0.5 md:py-1 text-white rounded-lg text-sm md:text-lg">
                  सालाना
                  </button>
                </a>
                
              </div>
            </div>
            <div className="container mx-auto my-4 md:my-10">
              <h6 className="tracking-wide mx-4">{horoscope ?? ""}</h6>
            </div>
            <div className="container mx-auto grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-10 work-sans">
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/yearly-rashifal/mesh')}}>
                <img src={Aries} className="h-40 w-40 object-contain" />
                <h6 className="text-black">मेष</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Mar 21 - Apr 19
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/yearly-rashifal/vrishabh')}}>
                <img src={Taurus} className="h-40 w-40 object-contain" />
                <h6 className="text-black">वृषभ</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Apr 20 - May 20
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/yearly-rashifal/mithun')}}>
                <img src={Gemini} className="h-40 w-40 object-contain" />
                <h6 className="text-black">मिथुन</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  May 21 - Jun 21
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/yearly-rashifal/kark')}}>
                <img src={Cancer} className="h-40 w-40 object-contain" />
                <h6 className="text-black">कर्क</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Jun 22 - Jul 22
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/yearly-rashifal/sinh')}}>
                <img src={Leo} className="h-40 w-40 object-contain" />
                <h6 className="text-black">सिंह</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Jul 23 - Aug 22
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/yearly-rashifal/kanya')}}>
                <img src={Virgo} className="h-40 w-40 object-contain" />
                <h6 className="text-black">कन्या</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Aug 23 - Sep 22
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/yearly-rashifal/tula')}}>
                <img src={Libra} className="h-40 w-40 object-contain" />
                <h6 className="text-black">तुला</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Sep 23 - Oct 23
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/yearly-rashifal/dhanu')}}>
                <img src={Sagittarius} className="h-40 w-40 object-contain" />
                <h6 className="text-black">धनु</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Oct 24 - Nov 21
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/yearly-rashifal/vrishchik')}}>
                <img src={Scorpio} className="h-40 w-40 object-contain" />
                <h6 className="text-black">वृश्चिक</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Nov 22 - Dec 21
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/yearly-rashifal/makar')}}>
                <img src={Capricorn} className="h-40 w-40 object-contain" />
                <h6 className="text-black">मकर</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Dec 22 - Jan 19
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/yearly-rashifal/kumbh')}}>
                <img src={Aquarius} className="h-40 w-40 object-contain" />
                <h6 className="text-black">कुंभ</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Jan 20 - Feb 18
                </h6>
              </a>
              <a className="cursor-pointer flex flex-col items-center justify-center rounded-xl shadow mx-0 md:mx-4" onClick={()=>{navigate('/hindi/rashifal/yearly-rashifal/meen')}}>
                <img src={Pisces} className="h-40 w-40 object-contain" />
                <h6 className="text-black">मीन</h6>
                <h6 className="tracking-wide text-sm text-gray-500 mb-8">
                  Feb 19 - Mar 20
                </h6>
              </a>
            </div>
            
          </div>
        <div className="container mx-auto mt-10">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
</main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
